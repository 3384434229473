// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAVjub0aMI3J2qzi4z0AkR6y4JW_3q_S6s",
  authDomain: "weshare-cb95e.firebaseapp.com",
  databaseURL: "https://weshare-cb95e-default-rtdb.firebaseio.com",
  projectId: "weshare-cb95e",
  storageBucket: "weshare-cb95e.appspot.com",
  messagingSenderId: "782304480695",
  appId: "1:782304480695:web:c21f0012ac75867a542c32",
  measurementId: "G-L032KP83D2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// const analytics = getAnalytics(app);