
import React, { useEffect } from 'react';
import SubscribeLayout from './SubscribeLayout';
import { db } from '../firebase-config';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';

const Unsubscribe = () => {

  const userCollection = collection(db, "earlyAccess");
  let emailadd = true;

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('email');
  let pl = "+";
  let final = myParam.replaceAll(" ", pl);

  // Decrypt
  var bytes  = CryptoJS.AES.decrypt(final, 'weshare');
  var originalEmail = bytes.toString(CryptoJS.enc.Utf8);
  console.log("email = "+originalEmail);

  useEffect(()=>{

    if(emailadd){
      const addEmail = async () => {
        // check email already exist or not
        const userData = await getDocs(userCollection);
        let emailIdStore = "";
        const dd = userData.docs.map((doc)=> ({...doc.data(), id: doc.id}));
        let emailStatus = false;
    
        dd.forEach(data => {
          if((data.email) == originalEmail){
            emailIdStore = data.id;
            emailStatus = true;
          }
        });

        if(emailStatus){
          const userDoc = doc(db, "earlyAccess", emailIdStore);
          await deleteDoc(userDoc);

          console.log("unsubscribe successfully");
        }else{
          console.log("allready unsubscribe");
        }
      }

      addEmail();
    }

    emailadd = false;
  }, []);


  return (
    <>
        <SubscribeLayout 
            heading = "Unsubscribe"
            title = "you have unsubscribed successfully."
            link = {document.location.origin}
        />
    </>
  )
}

export default Unsubscribe
