import React from 'react';
import Styles from '../css/features.module.scss';

const Features = (props) => {
  return (
    <div className={Styles.box}>
      <div className={Styles.boxImg}>
        <img src={props.img} alt="not supported" />
      </div>
      <div className={Styles.boxText}>
        <p>{props.text}<br/>{props.text1}</p>
      </div>
    </div>
  )
}

export default Features
