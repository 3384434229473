import React, { useEffect, useState } from 'react';
import Styles from '../css/other.module.scss';
import Feature from '../sections/Features';
import Scan from '../img/scan.png';
import Storage from '../img/storage.png';
import Resolution from '../img/resolution.png';
import Offline from '../img/offline.png';
import Pocket from '../img/pocket.png';
import Device1 from '../img/device1.png';
import Device2 from '../img/device2.png';
import Device3 from '../img/device3.png';
import Device4 from '../img/device4.png';
import Benefit1 from '../img/benefit1.png';
import Benefit2 from '../img/benefit2.png';
import Benefit3 from '../img/benefit3.png';
import Reload from '../img/reloadIcon.png';
import Logo from '../../navigation/img/logo.png';
import Video from '../WeshareVideo.mp4';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { db } from '../../../firebase-config';
import { collection, getDocs } from "firebase/firestore";
import CryptoJS from 'crypto-js';
import emailjs from '@emailjs/browser';

const Other = () => {

  const [email, setEmail] = useState("");
  const userCollection = collection(db, "earlyAccess");

  const setEmailValue = (event) => {
    setEmail(event.target.value);
  }

  const createEmail = async () => {
    var position = 0;
    var popupMessageValue = "";

    function popupMessage(){
      if(position == 5){
        clearInterval(animate);
      }else{
        position++;
        document.getElementById("popupMessage").style.top = position+"%";

        setTimeout(()=> {
          document.getElementById("popupMessage").style.top = "-20%";
        }, 3000);
      }
    }
  
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      const userData = await getDocs(userCollection);
      const dd = userData.docs.map((doc)=> ({...doc.data(), id: doc.id}));
      let emailStatus = false;

      dd.forEach(data => {
        if((data.email) == email){
          emailStatus = true;
        }
      });

      if(!emailStatus){
        popupMessageValue = "Please check your email account and verify your email address.";
        document.getElementById("messageShow").innerText = popupMessageValue;
        var animate = setInterval(popupMessage, 6);

        // Encrypt email
        const encryptEmail = CryptoJS.AES.encrypt(email, 'weshare').toString();
        console.log("enc = " + encryptEmail);

        var templateParams = {
          email: email,
          link: document.location.origin + "/subscribe?email=" + encryptEmail
        };

        // send verify email
        emailjs.send('service_egkwjuh', 'template_o5r0f3i', templateParams, 'aeVU7nXODKaHLEcpB')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        document.getElementById("emailbottom2").value = "";
        document.documentElement.scrollTop = 0;
        console.log("email added");
      }else{

        document.getElementById("emailbottom2").value = "";
        popupMessageValue = "This email address is already exist.";
        document.getElementById("messageShow").innerText = popupMessageValue;
        var animate = setInterval(popupMessage, 6);
        console.log("email already exist");
      }
    }else{
      document.getElementById("emailbottom2").value = "";
      popupMessageValue = "Please enter valid email address.";
      document.getElementById("messageShow").innerText = popupMessageValue;
      var animate = setInterval(popupMessage, 6);
      console.log("emial id is wrong");
    }
  }

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={Styles.others}>

      <div className={Styles.popupMessage} id='popupMessage'>
        <p id='messageShow'>Please check email address and verify your email address.</p>
      </div>
      
      {/* video section start */}
      <div className={Styles.videoSection}>
        <h3>Check out our awesome photo-sharing app that<br/><b>brings your memories to life!</b></h3>
        <div className={Styles.video}>
          {/* <video src={Video}></video> */}
          <video controls>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      {/* video section end */}

      {/* counting section start */}
      <div className={Styles.countingSection}>
        <p className={Styles.first}>Join our photo sharing community and<br/><b>capture your moments forever!!</b></p>
        <h2>1,000+</h2>
        <p className={Styles.last}>Individuals & communities choose<br /><b>WESHARE.</b></p>
      </div>
      {/* counting section end */}

      {/* feature section start */}
      <div className={Styles.features}>
        <div className={Styles.info}>
            <p>Elevate your PhotoSharing<br />Game with these</p>
            <h2>AMAZzzzzzING Features</h2>
        </div>

        <div className={Styles.featureList}>
            <Feature img={Scan} text="Scan QR CODE" text1="to join an event"/>
            <Feature img={Storage} text="Free 5GB" text1="Data Storage"/>
            <Feature img={Resolution} text="High" text1="Resolution"/>
            <Feature img={Offline} text="Offline" text1="Accessible"/>
            <Feature img={Pocket} text="Pocket" text1="Friendly Plans"/>
            <Feature img={Reload} text="Automation" text1="Sync"/>
        </div>
      </div>
      {/* feature section end */}

      {/* process start */}
      <div className={Styles.flow}>
        <div className={Styles.heading}>
          <p>Our streamlined process for</p>
          <h1>Hassle-Free Photo Sharing</h1>
        </div>

        <div className={Styles.devices}>
          <div className={`${Styles.device1} ${Styles.deviceL}`}>
            <div className={`${Styles.img} `} data-aos="fade-up"><img src={Device1} alt="not supported" /></div>
            <div className={Styles.labelL}><h2>Login/<br />Sign Up</h2></div>
          </div>

          <div className={Styles.ltor}>
            <svg width="956" height="749" viewBox="0 0 956 749" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id='mypath' d="M10.4996 0.500977C-115 402 1044 372 949 748.501" stroke="black" strokeDasharray="20 20"/>
            </svg>
          </div>

          <div className={`${Styles.device1} ${Styles.deviceR}`}>
            <div className={`${Styles.img} `}><img src={Device2} alt="not supported" /></div>
            <div className={Styles.labelR}><h2>Create<br />an Event</h2></div>
          </div>

          <div className={Styles.rtol}>
            <svg width="956" height="750" viewBox="0 0 956 750" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.516 749C-114.984 347.501 1044.02 377.501 949.016 0.999939" stroke="black" strokeDasharray="20 20"/>
            </svg>
          </div>

          <div className={`${Styles.device1} ${Styles.deviceL}`}>
            <div className={`${Styles.img} `}><img src={Device3} alt="not supported" /></div>
            <div className={Styles.labelL}><h2>Scan QR Code<br />to Join an Event</h2></div>
          </div>

          <div className={Styles.ltor}>
            <svg width="956" height="749" viewBox="0 0 956 749" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id='mypath' d="M10.4996 0.500977C-115 402 1044 372 949 748.501" stroke="black" strokeDasharray="20 20"/>
            </svg>
          </div>

          <div className={`${Styles.device1} ${Styles.deviceR}`}>
            <div className={`${Styles.img} `}><img src={Device4} alt="not supported" /></div>
            <div className={Styles.labelR}><h2>Join an<br />Event</h2></div>
          </div>

          <div className={Styles.lastLine}>
            <div className={Styles.lineL}>
            <svg width="447" height="750" viewBox="0 0 447 750" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.44085 749C-53.1263 347.501 487.747 377.501 443.413 1" stroke="black" strokeDasharray="20 20"/>
            </svg>
            </div>
          </div>

          <p className={Styles.lastP}>Enjoy Your<br />PhotoSharing Game</p>
        </div>
      </div>
      {/* process end */}

      {/* last section start */}
      <div className={Styles.lastSection}>
        <p>Ready to say goodbye to the photo</p>
        <h1>Manual photoSharing?</h1>

        <div className={Styles.ready} id='contactSec'>
          <input type="email" placeholder='Please enter your email address...'
            id='emailbottom2'
            onChange={setEmailValue}
          />
          <button onClick={createEmail}>YES! I'm Ready</button>
          <div className={Styles.error}><p>Your Email Address Is invalid.</p></div>
        </div>

        <div className={Styles.benefits}>
          <div>
            <img src={Benefit1} alt="not supported" />
            <p>No credit card required</p>
          </div>

          <div>
            <img src={Benefit2} alt="not supported" />
            <p>Offline Accessible</p>
          </div>

          <div>
            <img src={Benefit3} alt="not supported" />
            <p>Compatible for all Devices</p>
          </div>
        </div>
      </div>
      {/* last section end */}
      
      {/* footer section start */}
      <div className={Styles.footer}>
          <div className={Styles.footerMain}>
            <img src={Logo} alt="not supported" />
            <p>&copy; 2023 WeShare.</p>
          </div>
      </div>
      {/* footer section end */}

    </div>
  )
}

export default Other
