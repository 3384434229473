import React, { useState } from 'react';
import Styles from '../css/join.module.scss';
import Triangle1 from '../img/triangle1.png';
import Triangle2 from '../img/triangle2.png';
import { db } from '../../../firebase-config';
import { collection, getDocs } from "firebase/firestore";
import CryptoJS from 'crypto-js';
import emailjs from '@emailjs/browser';

const Join = () => {

  const [email, setEmail] = useState("");
  const userCollection = collection(db, "earlyAccess");

  const setEmailValue = (event) => {
    setEmail(event.target.value);
  }

  const createEmail = async () => {
    var position = 0;
    var popupMessageValue = "";

    function popupMessage(){
      if(position == 5){
        clearInterval(animate);
      }else{
        position++;
        document.getElementById("popupMessage").style.top = position+"%";

        setTimeout(()=> {
          document.getElementById("popupMessage").style.top = "-20%";
        }, 3000);
      }
    }
  
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      const userData = await getDocs(userCollection);
      const dd = userData.docs.map((doc)=> ({...doc.data(), id: doc.id}));
      let emailStatus = false;

      dd.forEach(data => {
        if((data.email) == email){
          emailStatus = true;
        }
      });

      if(!emailStatus){
        // await addDoc(userCollection, {email: email});
        popupMessageValue = "Please check your email account and verify your email address.";
        document.getElementById("messageShow").innerText = popupMessageValue;
        var animate = setInterval(popupMessage, 6);

        // Encrypt email
        const encryptEmail = CryptoJS.AES.encrypt(email, 'weshare').toString();
        console.log("enc = " + encryptEmail);

        var templateParams = {
          email: email,
          link: document.location.origin + "/subscribe?email=" + encryptEmail
        };

        // send verify email
        emailjs.send('service_aj8tiuh', 'template_2nvcd9k', templateParams, '2VCmFsUsJsTkqVvR4')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        document.getElementById("emailbottom").value = "";
        document.documentElement.scrollTop = 0;
        console.log("email added");
      }else{

        document.getElementById("emailbottom").value = "";
        popupMessageValue = "This email address is already exist.";
        document.getElementById("messageShow").innerText = popupMessageValue;
        var animate = setInterval(popupMessage, 6);
        console.log("email already exist");
      }
    }else{
      document.getElementById("emailbottom").value = "";
      popupMessageValue = "Please enter valid email address.";
      document.getElementById("messageShow").innerText = popupMessageValue;
      var animate = setInterval(popupMessage, 6);
      console.log("emial id is wrong");
    }
  }

  
  return (
    <div className={Styles.join}>

        {/* right bottom animations start */}
        <div className={Styles.home_animation}>
            <div className={Styles.animation_blocks}>
                <div className={Styles.animation_right}></div>
                <div className={Styles.animation_left}></div>
                <div className={Styles.animation_top}></div>
                <div className={Styles.animation_bottom}></div>
            </div>
            <div className={Styles.inner_circle}></div>
        </div>
        {/* right bottom animations start */}

        {/* middle text start */}
        <div className={Styles.text}>
          <h2>Say goodbye!</h2>
          <p>to manual photo sharing</p>
          <p>let WeShare work for you</p>
          <h3>Get you EARLY access now</h3>
          <div className={Styles.earlyAccess}>
            <input type="email" placeholder='Please enter your email address...'
              id='emailbottom'
              onChange={setEmailValue}
            />
            <button onClick={createEmail}>Join Now</button>
          </div>
          <p className={Styles.info}>No credit card required</p>
        </div>
        {/* middle text end */}

        {/* left bottom animation start */}
        <div className={Styles.left_bottom_animation}>
          <div className={Styles.innerLine}></div>
          <div className={Styles.innercircle}></div>
        </div>
        {/* left bottom animation end */}

        {/* top left animation start */}
        <div className={Styles.top_left_animation}>
          <div className={Styles.triangle_one}>
            <img src={Triangle1} alt="no supported" />
          </div>
          <div className={Styles.triangle_two}>
            <img src={Triangle2} alt="no supported" />
          </div>
        </div>
        {/* top left animation end */}

        {/* top right animation start */}
        <div className={Styles.top_right_animation}>
          <div className={Styles.circle_one}>
          </div>
          <div className={Styles.circle_two}>
          </div>          
        </div>
        {/* top right animation end */}


    </div>
  )
}

export default Join