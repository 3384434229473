import React from 'react';
import Styles from './css/home.module.scss';
import Join from './sections/Join';
import Other from './sections/Other';

const Home = () => {
  return (
    <div className={Styles.home}>
      <Join />
      <Other />
    </div>
  )
}

export default Home
