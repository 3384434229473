import React from 'react'
import Styles from './subscribeUnsubscribe.module.scss';
import Logo from './navigation/img/logo.png';
import Verified from './verified.gif';
import { IoArrowBackCircleOutline } from "react-icons/io5";

const SubscribeLayout = (props) => {
  return (
    <>
        <div className={Styles.whiteBgMain}>
        <div className={Styles.subscribeUnsubscribe}>
            <div className={Styles.main}>
                <img src={Logo} alt="no supported" />
                <h1>{props.heading}</h1>
                <p>{props.title}</p>
                <div><img src={Verified} alt="right gif" /></div>
                <a href={props.link}><IoArrowBackCircleOutline />Back to Home Page</a>
            </div>
        </div>
        </div>
    </>
  )
}

export default SubscribeLayout
