import React, { useEffect } from 'react';
import SubscribeLayout from './SubscribeLayout';
import { db } from '../firebase-config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import emailjs from '@emailjs/browser';

const Subscribe = () => {

  const userCollection = collection(db, "earlyAccess");
  let emailadd = true;

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('email');
  let pl = "+";
  let final = myParam.replaceAll(" ", pl);

  // Decrypt
  var bytes  = CryptoJS.AES.decrypt(final, 'weshare');
  var originalEmail = bytes.toString(CryptoJS.enc.Utf8);
  console.log("email = "+originalEmail);

  useEffect(()=>{

    if(emailadd){
      const addEmail = async () => {
        // check email already exist or not
        const userData = await getDocs(userCollection);
        const dd = userData.docs.map((doc)=> ({...doc.data(), id: doc.id}));
        let emailStatus = false;
    
        dd.forEach(data => {
          if((data.email) == originalEmail){
            emailStatus = true;
          }
        });
    
        if(!emailStatus){
          await addDoc(userCollection, {email: originalEmail});
          var changeValue = window.location.href;
          var replaceLink = changeValue.replace("subscribe", "unsubscribe");

          var templateParams = {
            email: originalEmail,
            link: replaceLink
          };

          // send verify email
          emailjs.send('service_egkwjuh', 'template_o5r0f3i', templateParams, 'aeVU7nXODKaHLEcpB')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          console.log("email added");
        }else{
          console.log("email already exist");
        }
      }

      addEmail();
    }

    emailadd = false;
  }, []);

  return (
    <>
        <SubscribeLayout 
            heading = "Verify your email"
            title = "your email address has been verified successfully."
            link = {document.location.origin}
        />
    </>
  )
}

export default Subscribe