import React from 'react';
import Home from './components/home/Home';
import Subscribe from './components/Subscribe';
import Unsubscribe from './components/Unsubscribe';
import Navigation from './components/navigation/Navigation';
import Privacy from './components/PrivacyPolicy';
// import Error from './components/Error';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>        
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/subscribe" element={<Subscribe />}></Route>
        <Route path="/unsubscribe" element={<Unsubscribe />}></Route>
        <Route path="/privacyPolicy" element={<Privacy />} ></Route>
        {/* <Route path="/error" element={<Error />}></Route> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App

// http://weshareapp.photos/subscribe?email=U2FsdGVkX19r1mu+KR5WwRU3FgAwtcDKPoBqt5m83rTx/i0Ru/Z0frZ+tpW2AmCV