import React from 'react';
import Styles from './css/navigation.module.scss';
import Logo from './img/logo.png';
// import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <div className={Styles.nav}>
        {/* logo */}
        <div className={Styles.logo}>
          <img src={Logo} alt="not supported" />
        </div>

        {/* subscribe */}
        <div className={Styles.subscribe}>
            {/* <Link to='/home'>Subscribe - <span>Free</span></Link> */}
            <a href='#contactSec'>Subscribe - <span>Free</span></a>
        </div>
    </div>
  )
}

export default Navigation
